import UserService from "KeyCloackServices/UserService"
import { UPDATE_PROFILE_SUCCESS } from "../profile/actionTypes"
import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  PROFILE_SUCCESS,
  POST_RESET_PASSWORD_REQUEST_SUCCESS,
  LOGIN_BY_KC_SUCCESS,
} from "./actionTypes"

const initialState = {
  isAuthenticated: false,
  user: {},
  error: "",
  success: "",
  loading: false,
  userRole: UserService.getRole()?.roles?.includes("admin")
    ? "admin"
    : UserService.getRole()?.roles?.includes("pro")
      ? "pro"
      : "seeker",
}

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        error: "",
        success: "",
        loading: true,
      }
      break
    case LOGIN_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "",
        isAuthenticated: true,
        user: { ...action.payload },
        loading: false,
        isUserLogout: false,
      }
      break
    case LOGIN_BY_KC_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "",
        isAuthenticated: true,
        user: { ...action.payload.data.records },
        loading: false,
        isUserLogout: false,
      }
      break
    case UPDATE_PROFILE_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "Update profile success!",
      }
      break
    case POST_RESET_PASSWORD_REQUEST_SUCCESS:
      state = {
        ...state,
        error: "",
        success: "",
      }
      break
    case PROFILE_SUCCESS:
      state = {
        ...state,
        error: "",
        success: action.payload.message,
        user: { ...action.payload.userInfo },
      }
      break
    case LOGOUT_USER:
      state = {
        ...state,
        error: "",
        success: "",
      }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state, isUserLogout: true }
      break
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        isUserLogout: false,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default login
