import { useEffect, useMemo, useState } from "react"
import parse from "html-react-parser"
import { useSelector } from "react-redux"
import ShowSources from "pages/MagicMycChat/showSources"
import FormattedResponse from "lib/FormattedResponse"

export const BotThinking = () => {
  const { botProfile } = useSelector(state => state.bot)

  const [loadingTime, setLoadingTime] = useState(0)
  let timer
  useEffect(() => {
    timer = setInterval(() => {
      setLoadingTime(value => value + 1)
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  })

  const thinkingContent = useMemo(() => {
    if (loadingTime === 0) {
      return "Sending your request to the neural net. Please stand by!"
    } else if (loadingTime > 0 && loadingTime < 3) {
      return "Thank you for your question! Stand by while I look for relevant keywords to upsert your request!"
    } else if (loadingTime >= 3 && loadingTime < 6) {
      return "Relevant text located! Sending upserted request to neural net…"
    } else {
      clearInterval(timer)
      return "Thank you for your question! Stand by while I look for relevant keywords to upsert your request!"
    }
  }, [loadingTime])

  return (
    <>
      <li class="chat incoming">
        <span class="material-symbols-outlined">
          <img
            src={botProfile ? botProfile?.iconUrl : ""}
            alt="thinking bot"
            width="100%"
          />
        </span>
        <p>{thinkingContent}</p>
      </li>
    </>
  )
}

export const BotMessage = ({
  message,
  messageIndex,
  allMessages,
  refer,
  questionId,
  question,
}) => {
  const [currentWord, setCurrentWord] = useState(0)
  const [isComplete, setIsComplete] = useState(false)

  useEffect(() => {
    if (currentWord >= message.length) {
      setIsComplete(true)
      return
    }
    let currentIndex = 0
    const interval = setInterval(() => {
      setCurrentWord(
        prevWord =>
          prevWord +
          message.split(" ")[currentIndex].length +
          message.split(" ")[currentIndex - 1].length,
      )
      currentIndex++
    }, 50)

    return () => {
      clearInterval(interval)
    }
  }, [currentWord, message])

  return (
    <>
      <p className="flex flex-col justify-start items-start ">
        {
          messageIndex == allMessages.length - 1 && allMessages.length !== 1 ? (
            <FormattedResponse response={message.substring(0, currentWord)} />
          ) : (
            // parse(message.substring(0, currentWord).replace(/\n/g, "<br>"))
            <FormattedResponse response={message} />
          )
          // parse(message.replace(/\n/g, "<br>"))
        }
        {messageIndex == 0 && !isComplete && (
          <span
            style={{
              marginLeft: "5px",
            }}
          >
            ...
          </span>
        )}
        {refer && refer.length > 0 ? (
          <>
            <ShowSources
              personalityBot={true}
              questionId={questionId}
              question={question}
              message={message}
              refer={refer}
            />
          </>
        ) : null}
      </p>
    </>
  )
}
