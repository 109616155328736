import {
  PROFILE_ERROR,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  POST_Delete_Profile_SUCCESS,
  GET_ALL_TITLES_SUCCESS,
  GET_ALL_EDUCATIONS_SUCCESS,
  GET_ALL_EXPERTISES_SUCCESS,
  GET_ALL_TRAININGS_SUCCESS,
} from "./actionTypes"

const initialState = {
  allTitles: [],
  allEducation: [],
  allExpertise: [],
  allTrainings: [],
  error: "",
  success: "",
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state }
      break
    case PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null }
      break
    case GET_ALL_TITLES_SUCCESS:
      let titles = []
      action.payload.data.records?.map(_item => {
        if (_item.role !== "user") {
          titles.push({
            label: _item.title,
            value: _item.title,
          })
        }
        return []
      })
      state = {
        ...state,
        allTitles: titles,
      }
      break
    case GET_ALL_EDUCATIONS_SUCCESS:
      let educations = []
      action.payload.data.records?.map(_item => {
        if (_item.role !== "user") {
          educations.push({
            label: _item.education,
            value: _item.education,
          })
        }
        return []
      })
      state = {
        ...state,
        allEducation: educations,
      }
      break
    case GET_ALL_EXPERTISES_SUCCESS:
      let expertises = []
      action.payload.data.records?.map(_item => {
        if (_item.role !== "user") {
          expertises.push({
            label: _item.expertise,
            value: _item.expertise,
          })
        }
        return []
      })
      state = {
        ...state,
        allExpertise: expertises,
      }
      break
    case GET_ALL_TRAININGS_SUCCESS:
      let trainings = []
      action.payload.data.records?.map(_item => {
        if (_item.role !== "user") {
          trainings.push({
            label: _item.training,
            value: _item.training,
          })
        }
        return []
      })
      state = {
        ...state,
        allTrainings: trainings,
      }
      break
    case POST_Delete_Profile_SUCCESS:
      localStorage.removeItem("accessToken")
      localStorage.removeItem("botUniqueUserId")
      localStorage.removeItem("botAccessTokenEncoded")
      return { ...state }
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
