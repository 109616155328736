import React, { useRef } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import bgImg from "../../assets/images/testimonial-bg.png"
import { MdArrowForward, MdArrowBack } from "react-icons/md"
import { FaRegUserCircle } from "react-icons/fa"

const testimonialData = [
  {
    text: "Mush.Pro's AI assistant is a lifesaver! It handles so many of my routine client interactions, freeing me up to focus on deeper coaching work.",
    name: "Dr. Sarah E.",
    position: "Transformational Coach",
  },
  {
    text: "I was hesitant about taking on more clients, but Mush.Pro allows me to support more people without sacrificing quality or burning out. It's been transformative for my business.",
    name: "Chris P.",
    position: "Mindfulness Coach",
  },
  {
    text: "My clients love interacting with my AI assistant. It feels like an extension of my coaching, providing them with support and resources even when I'm not available.",
    name: "Maya L.",
    position: "Holistic Wellness Coach",
  },
  {
    text: "The AI insights Mush.Pro provides have been eye-opening. I can quickly pinpoint areas where my clients need extra support, making my sessions way more impactful.",
    name: "Alex T.",
    position: "Cognitive Behavioral Coach",
  },
]

function Testimonial() {
  const articleCarosel = useRef()
  const sliderNext = () => {
    articleCarosel.current.slickNext()
  }
  const sliderPrev = () => {
    articleCarosel.current.slickPrev()
  }
  const settingsForArticle = {
    autoplay: false,
    arrows: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  const settingsForPeople = {
    centerMode: true,
    centerPadding: "0px",
    autoplay: false,
    arrows: false,
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 1,
  }
  return (
    <>
      <div className="appie-testimonial-about-area pb-45 pt-[45px]">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">Testimonials</h3>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div
                className="testimonial-about-slider-active"
                style={{ position: "relative" }}
              >
                <span
                  onClick={sliderPrev}
                  className="prev slick-arrow flex justify-center items-center"
                >
                  <MdArrowBack className="text-2xl" />
                </span>
                <Slider {...settingsForArticle} ref={articleCarosel}>
                  {testimonialData.map(user => (
                    <div className="testimonial-parent-item">
                      <div className="testimonial-box">
                        <div className="icon">
                          <div className="author-info flex flex-col justify-center items-center ">
                            <h3 className="title text-xl font-bold">
                              {user.name}
                            </h3>
                            <span>{user.position}</span>
                          </div>
                        </div>
                        <div className="ratings-icon">
                          <ul>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                            <li>
                              <i className="fas fa-star"></i>
                            </li>
                          </ul>
                        </div>
                        <p>{user.text}</p>
                      </div>
                    </div>
                  ))}
                </Slider>
                <span
                  onClick={sliderNext}
                  className="next slick-arrow flex justify-center items-center"
                >
                  <MdArrowForward className="text-2xl" />
                </span>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-8 col-sm-10">
              <div className="testimonial-box-about-slider-small-active">
                <Slider {...settingsForPeople} ref={peopleCarosel}>
                  <div className="item">
                    <div className="thumb flex justify-end">
                      <FaRegUserCircle className="text-4xl" />
                    </div>
                    <div className="content text-center">
                      <h5 className="title">Dr. Sarah E.</h5>
                      <span> Transformational Coach</span>
                    </div>
                  </div>
                  <div className="item">
                    <div className="thumb flex justify-end">
                      <img src="/assets/images/testimonial-user-1.png" alt="" />
                    </div>
                    <div className="content text-center">
                      <h5 className="title">Bodrum Salvador</h5>
                      <span>Product Designer</span>
                    </div>
                  </div>
                  <div className="item">
                    <div className="thumb flex justify-end">
                      <img src="/assets/images/testimonial-user-1.png" alt="" />
                    </div>
                    <div className="content text-center">
                      <h5 className="title">Bodrum Salvador</h5>
                      <span>Product Designer</span>
                    </div>
                  </div>
                  <div className="item ">
                    <div className="thumb flex justify-end">
                      <img src="/assets/images/testimonial-user-1.png" alt="" />
                    </div>
                    <div className="content text-center">
                      <h5 className="title">Bodrum Salvador</h5>
                      <span>Product Designer</span>
                    </div>
                  </div>
                </Slider>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Testimonial
