import React, { useEffect, useState } from "react"
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  Textarea,
  Tooltip,
  IconButton,
} from "@material-tailwind/react"
import { Modal } from "reactstrap"
import * as messages from "./../../../services/messages"
import { useMutation } from "react-query"
import http from "./../../../services/http-common"
import { toast } from "react-toastify"
import { FaRegEdit } from "react-icons/fa"

function EditFaqs({ id, getFaqs }) {
  const [open, setOpen] = React.useState(false)
  const [faqContent, setFaqContent] = useState({
    question: "",
    answer: "",
  })
  const [loading, setLoading] = useState(false)

  const getFaqById = useMutation(
    faqId => {
      return http.post("user/quick-replies/findById", {
        id: faqId,
      })
    },
    {
      onSuccess: ({ data }) => {
        setFaqContent(data?.quickReplies)
        setFaqContent({
          ...faqContent,
          question: data?.quickReplies?.question,
          answer: data?.quickReplies?.answer,
        })
      },
      onError: error => {
        toast.error(error)
      },
    },
  )

  useEffect(() => {
    getFaqById.mutate(id)
    setFaqContent({ ...faqContent, id: id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const errorFunc = error => {
    let message
    switch (error.response.status) {
      case 500: {
        message = messages.SERVER_ERROR
        break
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR
        break
      }
      case 400: {
        message = messages.BAD_REQUEST
        break
      }
      default: {
        message = error.message
      }
    }
    toast.error(message)
  }

  const updateContent = useMutation(
    () => {
      return http.put(
        `user/quick-replies/update`,
        {
          id: id,
          question: faqContent.question,
          answer: faqContent.answer,
        },
        null,
      )
    },
    {
      onSuccess: ({ data }) => {
        toast.success("Successfully Updated!")
        getFaqs()
        setFaqContent({
          question: "",
          answer: "",
        })
        handleOpen()
      },
      onError: error => {
        errorFunc(error)
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )

  const handleOpen = () => {
    setOpen(!open)
  }

  const onChangeContent = e => {
    if (loading) return false
    setFaqContent({ ...faqContent, [e.target.name]: e.target.value })
  }

  const onSubmit = () => {
    updateContent.mutate()
  }

  return (
    <>
      <Tooltip content="Edit">
        <IconButton variant="text" onClick={handleOpen}>
          <FaRegEdit className="h-5 w-5 text-[#7a6fbe]" />
        </IconButton>
      </Tooltip>
      <Modal
        isOpen={open}
        toggle={() => {
          handleOpen()
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Update Suggestion</h5>
          <button
            type="button"
            onClick={() => {
              handleOpen(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form className="mt-2 mb-2  w-auto  max-w-screen-md sm:w-full ">
            <div className="mb-1 flex flex-col gap-6 w-auto ">
              <div className="sm:w-auto md:w-full">
                <Textarea
                  className="w-full focus:border-t-0"
                  name="question"
                  type="text"
                  label="Question"
                  value={faqContent?.question}
                  onChange={onChangeContent}
                />
              </div>
            </div>
          </form>
          <div className="flex justify-end items-center">
            <Button
              size="sm"
              variant="tex"
              onClick={handleOpen}
              className="mr-1 bg-gray-700"
              disabled={loading}
            >
              <span>Close</span>
            </Button>
            <Button
              size="sm"
              className="bg-[#7a6fbe]"
              onClick={onSubmit}
              disabled={loading}
            >
              <span>Update</span>
            </Button>
          </div>
        </div>
      </Modal>
      <Dialog
        open={false}
        handler={handleOpen}
        className="h-140"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody divider className="flex-col max-h-full">
          <div className="w-full flex justify-center gap-2 items-center">
            Update Suggestion
          </div>
          <form className="mt-8 mb-2  w-auto  max-w-screen-md sm:w-full ">
            <div className="mb-1 flex flex-col gap-6 w-auto ">
              <div className="sm:w-auto md:w-full">
                <Textarea
                  className="w-full"
                  name="question"
                  type="text"
                  label="Question"
                  value={faqContent?.question}
                  onChange={onChangeContent}
                />
              </div>
            </div>
          </form>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
            disabled={loading}
          >
            <span>Close</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={onSubmit}
            disabled={loading}
          >
            <span>Update</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}

export default EditFaqs
