import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  CardImg,
  Card,
  CardBody,
  Label,
  FormFeedback,
  Alert,
  Input,
  CardTitle,
} from "reactstrap"
import { Button, Box } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import { PhoneInput } from "react-international-phone"
import { isValidPhoneNumber } from "libphonenumber-js"
import Creatable from "react-select/creatable"

// Formik Validation
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import withRouter from "components/Common/withRouter"
import toast from "react-hot-toast"

// actions
import seekerLogo from "../../assets/images/seeker.png"
import { handleUploadFilesData, updateProfile } from "store/actions"
import { handleUploadLogo } from "store/bot/actions"
import AlertDeleteAccount from "./AlertDeleteAccount"
import { isEmpty } from "lodash"
import Chip from "@mui/material/Chip"
import { CiEdit } from "react-icons/ci"
import { allExpertise, theraupatic_approaches } from "common/data/profileData"
import Miniwidget from "pages/Dashboard/Miniwidget"

const UserProfile = () => {
  document.title = "Profile | Mush.Pro by The Entheology Project"
  const { user } = useSelector(state => state.Login)
  const [fileName, setFileName] = useState("Change Profile")
  const [phoneError, setPhoneError] = useState("")
  const [loading, setLoading] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [editTitles, setEditTitles] = useState(false)
  const [editExpertise, setEditExpertise] = useState(false)

  const [addressData, setAddressData] = useState({
    city: user?.address?.city || "",
    state: user?.address?.state || "",
    country: user?.address?.country || "",
  })
  const [previewAvatar, setPreviewAvatar] = useState(
    user.avatar ? user.avatar : seekerLogo,
  )
  const dispatch = useDispatch()

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    error: login.error,
    success: login.success,
  }))

  const { error, success } = useSelector(LoginProperties)

  const onUploadCallBack = ({ status, returnData }) => {
    if (status === "success") {
      setIsUploading(false)
      validation.setFieldTouched("avatar", true)
      validation.setFieldValue("avatar", returnData.data.imageUrl)
      setFileName("Upload Successfully")
    } else {
      setIsUploading(false)
      console.log(returnData.message)
    }
  }

  const imageSelect = e => {
    console.log("clicked")
    setIsUploading(true)
    const formData = new FormData()
    formData.append("file", e.currentTarget.files[0])
    dispatch(handleUploadLogo(formData, onUploadCallBack))
    e?.currentTarget?.files[0] &&
      setPreviewAvatar(URL.createObjectURL(e.currentTarget.files[0]))
  }

  const onUploadCallBackData = ({ status, returnData }) => {
    if (status === "success") {
      setLoading(false)
      validation.setFieldTouched("files", true)
      validation.setFieldValue("files", returnData.data.records?.split(", "))
      toast.success("Files uploaded successfully.")
    } else {
      setLoading(false)
      setIsUploading(false)
      console.log(returnData.message)
    }
  }

  const fileSelect = e => {
    let files = Array.prototype.slice.call(e.currentTarget.files)
    const formData = new FormData()
    files.forEach(file => {
      formData.append("books", file)
    })
    setLoading(true)
    dispatch(handleUploadFilesData(formData, onUploadCallBackData))
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      given_name: user.given_name,
      email: user.email,
      phone_number: user.phone_number || "",
      avatar: user.avatar || "",
      address: addressData || {
        city: "",
        state: "",
        country: "",
      },
      companyName: user.companyName || "",
      websiteUrl: user.websiteUrl || "",
      theraupatic_approaches: [user.theraupatic_approaches] || "",
      files: [user.files] || "",
      expertise: [user.expertise] || "",

      submit: null,
    },
    onSubmit: async (values, helpers) => {
      setLoading(true)
      validation.setFieldTouched("address", true)
      validation.setFieldValue("address", addressData)
      dispatch(updateProfile(values, helpers, setLoading))
      setEditTitles(false)
      setEditExpertise(false)
    },
  })

  const onPhoneNumberChange = c => {
    validation.setFieldTouched("phone_number", true)
    validation.setFieldValue("phone_number", c)
    if (!isValidPhoneNumber(c)) {
      setPhoneError("Invalid Phone Number")
    } else {
      setPhoneError("")
    }
  }
  const handleChangeAddress = e => {
    setAddressData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  useEffect(() => {
    user?.avatar && setPreviewAvatar(user?.avatar ? user?.avatar : seekerLogo)
  }, [user])

  function handleChangeTheraupatic(values) {
    let newArr = values?.map(obj => obj.value)
    validation.setFieldTouched("theraupatic_approaches", true)
    validation.setFieldValue("theraupatic_approaches", newArr)
  }

  function handleChangeExpertise(values) {
    let newArr = values?.map(obj => obj.value)
    validation.setFieldTouched("expertise", true)
    validation.setFieldValue("expertise", newArr)
  }

  const handleToggle = value => {
    value === "title" && setEditTitles(!editTitles)
    value === "expertise" && setEditExpertise(!editExpertise)
  }

  useEffect(() => {
    ;(error || success) && setLoading(false)
  }, [error, success])

  return (
    <React.Fragment>
      {user.isAdmin && <Miniwidget />}
      <Card>
        <CardBody>
          <CardTitle className="h4 mb-4">Update Profile</CardTitle>

          <div className="page-content p-0">
            <Container fluid>
              {error && error ? (
                <Alert color="danger" style={{ marginTop: "13px" }}>
                  {error}
                </Alert>
              ) : null}
              {success ? (
                <Alert color="success" style={{ marginTop: "13px" }}>
                  {success}
                </Alert>
              ) : null}
              <div className="flex justify-start items-center mb-3">
                <div className="d-flex">
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      mt: "20px",
                      mb: "10px",
                    }}
                  >
                    <CardImg
                      src={previewAvatar}
                      alt="Profile "
                      className="rounded-circle avatar-md max-w-[118px] max-h-[118px] sm:me-0 me-3"
                    />
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        size="small"
                        color="primary"
                        variant="outlined"
                        component="label"
                        className="rounded-sm"
                      >
                        {isUploading ? <CircularProgress /> : fileName}
                        <input
                          type="file"
                          name="avatar"
                          hidden
                          onChange={imageSelect}
                        />
                      </Button>
                    </Box>
                  </Box>
                </div>
              </div>
              <Row>
                <Col xl="6">
                  <div className="form-group mb-3 ">
                    <Label className="form-label">Name</Label>
                    <Input
                      name="given_name"
                      // value={name}
                      className="form-control"
                      placeholder="Enter User Name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.given_name || ""}
                      invalid={
                        validation.touched.given_name &&
                        validation.errors.given_name
                          ? true
                          : false
                      }
                    />
                    {validation.touched.given_name &&
                    validation.errors.given_name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.given_name}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Email</Label>
                    <Input
                      name="email"
                      // value={name}
                      className="form-control"
                      placeholder="Enter your valid Email"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Phone number</Label>
                    <PhoneInput
                      className="!w-100"
                      defaultCountry="us"
                      error={!!(validation.touched.phone_number && phoneError)}
                      helperText={validation.touched.phone_number && phoneError}
                      name="phone_number"
                      onBlur={validation.handleBlur}
                      onChange={onPhoneNumberChange}
                      type="text"
                      value={validation.values.phone_number}
                    />
                    {validation.touched.phone_number &&
                    validation.errors.phone_number ? (
                      <FormFeedback type="invalid">
                        {validation.errors.phone_number}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Company name</Label>
                    <Input
                      name="companyName"
                      // value={name}
                      className="form-control"
                      placeholder="Enter your company name."
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.companyName || ""}
                      invalid={
                        validation.touched.companyName &&
                        validation.errors.companyName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.companyName &&
                    validation.errors.companyName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.companyName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Website URL</Label>
                    <Input
                      name="websiteUrl"
                      // value={name}
                      className="form-control"
                      placeholder="Enter website url."
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.websiteUrl || ""}
                      invalid={
                        validation.touched.websiteUrl &&
                        validation.errors.websiteUrl
                          ? true
                          : false
                      }
                    />
                    {validation.touched.websiteUrl &&
                    validation.errors.websiteUrl ? (
                      <FormFeedback type="invalid">
                        {validation.errors.websiteUrl}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">City</Label>
                    <Input
                      name="city"
                      // value={name}
                      className="form-control"
                      placeholder="Please provide a valid city."
                      type="text"
                      onChange={e => {
                        handleChangeAddress(e)
                      }}
                      value={addressData.city}
                    />
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">State</Label>
                    <Input
                      name="state"
                      // value={name}
                      className="form-control"
                      placeholder="Please provide a valid state."
                      type="text"
                      onChange={e => {
                        handleChangeAddress(e)
                      }}
                      value={addressData.state}
                    />
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Zip</Label>
                    <Input
                      name="zip"
                      // value={name}
                      className="form-control"
                      placeholder="Zip Code"
                      type="text"
                      onChange={e => {
                        handleChangeAddress(e)
                      }}
                      value={addressData.zip}
                    />
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label">Country</Label>
                    <Input
                      name="country"
                      // value={name}
                      className="form-control"
                      placeholder="Country"
                      type="text"
                      onChange={e => {
                        handleChangeAddress(e)
                      }}
                      value={addressData.country}
                    />
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label flex justify-between items-center">
                      <span>Theraupatic Approaches</span>
                      <CiEdit
                        className="text-xl cursor-pointer"
                        onClick={() => {
                          handleToggle("title")
                        }}
                      />
                    </Label>
                    {editTitles || isEmpty(!user?.theraupatic_approaches) ? (
                      <Creatable
                        isMulti={true}
                        name="colors"
                        options={theraupatic_approaches}
                        className="basic-multi-select select2-selection"
                        onChange={v => {
                          handleChangeTheraupatic(v)
                        }}
                        classNamePrefix="select"
                      />
                    ) : (
                      <div>
                        {user?.theraupatic_approaches
                          ?.split(",  ")
                          .map(title => (
                            <Chip
                              label={title}
                              size="small"
                              variant="outlined"
                              className="mx-1 r"
                              sx={{
                                borderRadius: "5px",
                              }}
                            />
                          ))}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xl="6">
                  <div className="form-group mb-3">
                    <Label className="form-label flex justify-between items-center">
                      <span>Areas of Expertise</span>
                      <CiEdit
                        className="text-xl cursor-pointer"
                        onClick={() => {
                          handleToggle("expertise")
                        }}
                      />
                    </Label>
                    {editExpertise || isEmpty(!user?.expertise) ? (
                      <Creatable
                        isMulti={true}
                        name="colors"
                        options={allExpertise}
                        className="basic-multi-select select2-selection"
                        onChange={v => {
                          handleChangeExpertise(v)
                        }}
                        classNamePrefix="select"
                      />
                    ) : (
                      <div>
                        {user?.expertise?.split(",").map(title => (
                          <Chip
                            label={title}
                            size="small"
                            variant="outlined"
                            className="mx-1"
                            sx={{
                              borderRadius: "5px",
                            }}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </Col>{" "}
                <Col xl="6">
                  <label
                    for="formFileSm"
                    class="mb-2 inline-block text-neutral-500 dark:text-neutral-400"
                  >
                    Attach Documents
                  </label>
                  <input
                    class="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-secondary-500 bg-transparent bg-clip-padding px-3 py-[0.32rem] text-xs font-normal text-surface transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:me-3 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-e file:border-solid file:border-inherit file:bg-transparent file:px-3  file:py-[0.32rem] file:text-surface focus:border-primary focus:text-gray-700 focus:shadow-inset focus:outline-none dark:border-white/70 dark:text-white  file:dark:text-white"
                    id="formFileSm"
                    type="file"
                    multiple
                    onChange={fileSelect}
                  />
                </Col>
              </Row>

              <div className="d-flex justify-content-end align-items-center">
                <AlertDeleteAccount />
                <form noValidate onSubmit={validation.handleSubmit}>
                  <Button
                    color="primary"
                    type="submit"
                    className="!bg-[#7a6fbe] text-white !btn !btn-primary !w-md"
                    disabled={loading}
                  >
                    Save
                  </Button>
                </form>
              </div>
            </Container>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
