import React, { useEffect } from "react"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

import { PhoneInput } from "react-international-phone"
import "react-international-phone/style.css"
import { isValidPhoneNumber } from "libphonenumber-js"

import logoDark from "../../assets/images/ep_logo_trippy.png"
import logoLight from "../../assets/images/ep_logo_trippy.png"

// action
import { registerUser } from "../../store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import { Link, useNavigate } from "react-router-dom"
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import { useState } from "react"
import "./register.css"
import backgroundImg from "./../../assets/images/mgpt-bg-compressed.webp"
import GoogleSignIn from "./GoogleSignIn"

const Register = props => {
  //meta title
  document.title = "SignUp | MushPro by The Entheology Project"
  const [phoneError, setPhoneError] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: "",
      email: "",
      phoneNumber: "",
      password: "",
      confirmPassword: "",
      policy: false,
      submit: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .min(3, "Must be at least 3 characters")
        .max(255)
        .required("Required"),
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      password: Yup.string()
        .min(7, "Must be at least 7 characters")
        .max(255)
        .required("Required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Required"),
      policy: Yup.boolean().isTrue("You must accept the TOS").required(),
    }),
    onSubmit: async (values, helpers) => {
      dispatch(registerUser(values, navigate, helpers))
    },
  })

  const selectAccountState = state => state.Account
  const AccountProperties = createSelector(selectAccountState, account => ({
    user: account.user,
    registrationError: account.registrationError,
    success: account.success,
    // loading: account.loading,
  }))

  const {
    user,
    registrationError,
    success,
    // loading
  } = useSelector(AccountProperties)

  useEffect(() => {
    success && setTimeout(() => navigate("/login"), 2000)
  }, [success, navigate])

  const onPhoneNumberChange = c => {
    validation.setFieldTouched("phoneNumber", true)
    validation.setFieldValue("phoneNumber", c)
    if (!isValidPhoneNumber(c)) {
      setPhoneError("Invalid Phone Number")
    } else {
      setPhoneError("")
    }
  }

  const style = {
    authScreenPage: {
      height: "100vh",
      backgroundImage: `url(${backgroundImg})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      display: "flow-root",
    },
  }

  return (
    <React.Fragment>
      <div
        className="account-pages sm:pt-3 pt-5  "
        style={style.authScreenPage}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-3 mb-2">
                    <Link to="/" className="d-block auth-logo">
                      <img
                        src={logoDark}
                        alt="MushPro"
                        height="70px"
                        width="70px"
                        className="auth-logo-dark"
                      />
                      <img
                        src={logoLight}
                        alt="MushPro"
                        width="80"
                        height="80"
                        className="auth-logo-light"
                      />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Welcome Seeker
                    </h4>
                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {user && user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}
                      {validation.touched.policy && validation.errors.policy ? (
                        <Alert color="danger">{validation.errors.policy}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label htmlFor="firstName">Name</Label>
                        <Input
                          id="firstName"
                          name="firstName"
                          className="form-control"
                          placeholder="Enter Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.firstName || ""}
                          invalid={
                            validation.touched.firstName &&
                            validation.errors.firstName
                              ? true
                              : false
                          }
                        />
                        {validation.touched.firstName &&
                        validation.errors.firstName ? (
                          <FormFeedback type="invalid">
                            {validation.errors.firstName}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="useremail">Email address</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="phoneNumber">Phone Number</Label>
                        <PhoneInput
                          className="!w-100"
                          defaultCountry="us"
                          error={
                            !!(validation.touched.phoneNumber && phoneError)
                          }
                          helperText={
                            validation.touched.phoneNumber && phoneError
                          }
                          name="phoneNumber"
                          onBlur={validation.handleBlur}
                          onChange={onPhoneNumberChange}
                          type="text"
                          value={validation.values.phoneNumber}
                        />
                        {validation.touched.phoneNumber &&
                        validation.errors.phoneNumber ? (
                          <FormFeedback type="invalid">
                            {validation.errors.phoneNumber}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label htmlFor="password">Password</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="confirmPassword">
                          Confirm Password
                        </Label>
                        <Input
                          name="confirmPassword"
                          type="password"
                          placeholder="Enter password confirm"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ""}
                          invalid={
                            validation.touched.confirmPassword &&
                            validation.errors.confirmPassword
                              ? true
                              : false
                          }
                        />
                        {validation.touched.confirmPassword &&
                        validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">
                            {validation.errors.confirmPassword}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customControlInline"
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.policy &&
                              validation.errors.policy
                                ? true
                                : false
                            }
                            checked={validation.values.policy}
                            name="policy"
                            onChange={validation.handleChange}
                          />

                          <label
                            className="form-check-label "
                            htmlFor="customControlInline"
                          >
                            <p className="text-muted mb-0 font-size-14">
                              By signing up you accept the Term of service
                            </p>
                          </label>
                        </div>
                      </div>
                      <div className="mb-3 row mt-4">
                        <div className="col-12 text-end">
                          <button
                            className="btn w-100 btn-primary w-md waves-effect waves-light bg-[#7a6fbe]"
                            type="submit"
                          >
                            Register
                          </button>
                        </div>
                      </div>
                      <div className=" text-center ">
                        <GoogleSignIn />
                        <p>
                          Already have an account ?{" "}
                          <Link
                            to="/login"
                            className="font-semibold text-[#7a6fbe]"
                          >
                            {" "}
                            Sign In{" "}
                          </Link>{" "}
                        </p>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
