import { useCallback, useEffect, useState } from "react"
import http from "../../../services/http-common"
import { setSessionExpired, useMainController } from "../../../context"
import { useMutation } from "react-query"
import * as messages from "../../../services/messages"
import { toast } from "react-hot-toast"
import {
  Typography,
  Tooltip,
  IconButton,
  Button,
  Input,
  Card,
  CardHeader,
  CardBody,
} from "@material-tailwind/react"
import { FaPlus, FaTrashAlt } from "react-icons/fa"
import EditFaqs from "./EditFaqs"
import { useSelector } from "react-redux"

const BotSuggestions = props => {
  const { userRole } = useSelector(state => state.Login)

  const [dispatch] = useMainController()
  const [faqs, setFaqs] = useState(null)
  const [loading, setLoading] = useState(false)
  const [faqContent, setFaqContent] = useState({
    question: "",
    answer: "",
  })
  const errorFunc = error => {
    let message
    switch (error.response.status) {
      case 500: {
        message = messages.SERVER_ERROR
        break
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR
        break
      }
      case 400: {
        message = messages.BAD_REQUEST
        break
      }
      default: {
        message = error.message
      }
    }
    toast.error(message)
  }

  const getPrompt = useMutation(
    () => {
      return http.get(
        "user/quick-replies/custom-bot",
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        setFaqs(data.quickReplies)
      },
      onError: error => {
        toast.error(error)
      },
    },
  )

  const getFaqs = useCallback(() => {
    getPrompt.mutate()
  }, [])

  const deleteFaq = useMutation(
    async faqId => {
      return await http.deleteFaqs("user/quick-replies/delete", {
        userId: {
          id: faqId,
        },
      })
    },
    {
      onSuccess: () => {
        toast.success("Successfully Deleted!")
        getFaqs()
      },
      onError: error => {
        toast.error(error)
      },
    },
  )

  const onDeleteFaq = id => {
    deleteFaq.mutate(id)
  }

  const onChangeContent = e => {
    if (loading) return false
    setFaqContent({ ...faqContent, [e.target.name]: e.target.value })
  }

  const onSubmit = () => {
    saveContent.mutate()
  }

  const saveContent = useMutation(
    () => {
      return http.post(
        `${
          userRole === "admin"
            ? "user/quick-replies/createForAdmin"
            : "user/quick-replies/createForPro"
        }`,
        faqContent,
        null,
      )
    },
    {
      onSuccess: ({ data }) => {
        toast.success("Successfully Added!")
        getFaqs()
        setFaqContent({
          question: "",
          answer: "",
        })
      },
      onError: error => {
        errorFunc(error)
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )

  useEffect(() => {
    getFaqs()
  }, [])

  return (
    <>
      <div className="mt-4 mb-8 flex flex-col gap-12">
        <Card>
          <CardHeader color="gray" className="mb-8 p-6 bg-[#887bd9]">
            <Typography variant="h6" color="white">
              Suggestions
            </Typography>
          </CardHeader>
          <CardBody className="overflow-x-auto px-2 pt-0 pb-2">
            <div className="flex w-full justify-center">
              <div className="flex w-full flex-col gap-4">
                <div className="flex flex-col gap-6 px-4">
                  <Typography color="gray" variant="h6">
                    Please enter suggestion:
                  </Typography>
                  <div className="flex h-full w-full flex-row items-center gap-6">
                    <Input
                      className="border-neutral-300 text-neutral-700 file:bg-neutral-100 file:text-neutral-700 hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary relative m-0 block w-full min-w-0 flex-auto rounded border border-solid bg-clip-padding px-3 py-[0.32rem] text-base font-normal transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:px-3 file:py-[0.32rem] file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] focus:outline-none"
                      name="question"
                      type="text"
                      label="Question"
                      value={faqContent?.question}
                      onChange={onChangeContent}
                    />
                    <Button
                      size="lg"
                      className="flex items-center gap-3 py-2 px-4 bg-[#7a6fbe]"
                      disabled={loading}
                      onClick={onSubmit}
                    >
                      <FaPlus /> ADD
                    </Button>
                  </div>
                </div>
                <div>
                  {faqs && (
                    <table className="mt-4 w-full min-w-max table-auto text-left">
                      <tbody>
                        {faqs?.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td
                                className={
                                  "!py-1 !px-2 border-b border-t border-blue-gray-50"
                                }
                              >
                                <div className="flex items-center gap-3">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                    >
                                      <span className="font-bold me-">
                                        Question:{" "}
                                      </span>
                                      {item.question}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td
                                className={
                                  "flex justify-end items-center !p-1 border-b border-t border-blue-gray-50 "
                                }
                              >
                                <EditFaqs id={item.id} getFaqs={getFaqs} />
                                <Tooltip content="Delete">
                                  <IconButton
                                    variant="text"
                                    onClick={() => onDeleteFaq(item.id)}
                                  >
                                    <FaTrashAlt className="h-5 w-5 text-[#ec4561]" />
                                  </IconButton>
                                </Tooltip>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default BotSuggestions
