import axios from "axios"

const apiClient = axios.create({
  // baseURL: `https://api.mushgpt.com/`,
  // baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  // baseURL: `https://dev-api.mushgpt.com/`,
  // baseURL: `http://localhost:8000/`,
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

export default apiClient

export const apiClientForMushGpt = axios.create({
  baseURL: `https://api.mushgpt.com/`,
  // baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  // baseURL: `https://dev-api.mushgpt.com/`,
  // baseURL: `http://localhost:8000/`,
  // baseURL: process.env.REACT_APP_API_BASE_URL,
})
