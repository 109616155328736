import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import "./index.css"
import { createRoot } from "react-dom/client"

import store from "./store"
import { MainControllerProvider } from "context"
import UserService from "KeyCloackServices/UserService"

// const root = ReactDOM.createRoot(document.getElementById("root"))

// root.render(
//   <Provider store={store}>
//     <>
//       <MainControllerProvider>
//         <BrowserRouter basename={process.env.PUBLIC_URL}>
//           <App />
//         </BrowserRouter>
//       </MainControllerProvider>
//     </>
//   </Provider>,
// )

const renderApp = () =>
  createRoot(document.getElementById("root")).render(
    <Provider store={store}>
      <>
        <MainControllerProvider>
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <App />
          </BrowserRouter>
        </MainControllerProvider>
      </>
    </Provider>,
  )

// serviceWorker.unregister()
UserService.initKeycloak(renderApp)
