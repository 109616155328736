import { setSessionExpired, useMainController } from "context"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useMutation } from "react-query"
import { Input, Label } from "reactstrap"
import http from "services/http-common"

export function GreetingMessageEditor(props) {
  const [dispatch] = useMainController()
  const [greeting, setGreeting] = useState("")

  const onChangeGreeting = e => {
    setGreeting(e.target.value)
  }

  const getGreetingMessage = useMutation(
    () => {
      return http.get("chat/greeting", null, setSessionExpired, dispatch)
    },
    {
      onSuccess: ({ data }) => {
        setGreeting(data.message)
      },
      onError: error => {
        toast.error(error)
      },
    },
  )

  useEffect(() => {
    getGreetingMessage.mutate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveGreeting = useMutation(
    async () => {
      return await http.put("chat/greeting", { greeting: greeting })
    },
    {
      onSuccess: () => {
        toast.success("Successfully Saved!")
      },
      onError: error => {
        toast.error(error)
      },
    },
  )

  const onSaveGreeting = () => {
    saveGreeting.mutate()
  }
  return (
    <div className="flex flex-col w-full justify-center">
      <Label htmlFor="system-greeting">System Greeting</Label>
      <Input
        type="textarea"
        name="system-greeting"
        rows="8"
        label="System Greeting"
        value={greeting}
        onChange={onChangeGreeting}
      />
      {/* <div className="mt-5 flex w-full max-w-[800px] flex-col gap-4">
        
        <Textarea
          value={greeting}
          onChange={onChangeGreeting}
          label="Prompt for Chat"
          resize
          className="min-h-[300px]"
        />
        
      </div> */}
      <div className="flex justify-center mt-3">
        <button
          onClick={onSaveGreeting}
          className="btn btn-primary min-w-[150px]"
        >
          Save
        </button>
      </div>
    </div>
  )
}

export default GreetingMessageEditor
