import { call, put, all, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import * as actionTypes from "./actionTypes"
import { apiError, loginSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getVerify, postLogin } from "../../../helpers/backend_helper"
import axios from "axios"
import { API_URL } from "helpers/api_helper"
import { POST_JWT_Token } from "helpers/url_helper"
import {
  generateSessionId,
  getAccessToken,
} from "helpers/jwt-token-access/accessToken"
import apiClient from "helpers/apiClient"

// async function getJwtToken() {
//   if (localStorage.getItem("accessToken")) {
//     const myToken = await apiClient.post(
//       "/user/embedding-token",
//       {},
//       {
//         headers: { Authorization: `${getAccessToken()}` },
//       },
//     )
//     myToken && localStorage.setItem("botAccessTokenEncoded", myToken.data.token)
//     // }
//   }
// }
function* getUserInfo({ payload: { accessToken, history } }) {
  try {
    const response = yield call(getVerify, {
      accessToken: accessToken,
    })
    yield put(loginSuccess(response.user))
  } catch (error) {
    console.log(
      error?.response?.data?.message
        ? error?.response?.data?.message
        : error?.message,
    )
  }
}

async function getJwtToken(data) {
  if (localStorage.getItem("accessToken")) {
    if ((data?.role === "pro") | data?.isBotCreated) {
      const myToken = await axios.post(
        API_URL + POST_JWT_Token,
        {},
        {
          headers: { Authorization: `${getAccessToken()}` },
        },
      )
      myToken &&
        localStorage.setItem("botAccessTokenEncoded", myToken.data.token)
    }
  }
}
function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postLogin, {
      email: user.email,
      password: user.password,
    })
    // localStorage.setItem("authUser", JSON.stringify(response.user))
    localStorage.setItem("accessToken", response.access_token)
    localStorage.setItem("botUniqueUserId", generateSessionId())
    getJwtToken(response.user)
    yield put(loginSuccess(response.user))
    history("/appointments")
  } catch (error) {
    yield put(
      apiError(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
      ),
    )
  }
}

async function shooterGetEmbeddedToken(userInfo) {
  const myToken = await axios.post(
    API_URL + POST_JWT_Token,
    {},
    {
      headers: { Authorization: `${getAccessToken()}` },
    },
  )
  return myToken.data.token
}

function* getEmbeddedToken() {
  return yield takeLatest(actionTypes.GET_EMBEDDED_TOKEN, function* (action) {
    try {
      const data = yield call(shooterGetEmbeddedToken, action.payload)
      localStorage.setItem("botAccessTokenEncoded", data)
    } catch (error) {
      console.log(error)
    }
  })
}
async function shooterRegisterGoogleUser(userInfo) {
  return apiClient.post("/user/create", {
    firstName: userInfo.name,
    email: userInfo.email,
    password: userInfo.given_name + userInfo.id,
    confirmPassword: userInfo.given_name + userInfo.id,
    phoneNumber: "",
    emailVerify: true,
  })
}

function* signInGoogle() {
  return yield takeLatest(
    actionTypes.POST_GOOGLE_SIGNIN_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterRegisterGoogleUser, action.payload)
        yield put({
          type: actionTypes.POST_GOOGLE_SIGNIN_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        })
        action.navigate("/chat")
      } catch (error) {
        console.log(error)
      }
    },
  )
}

async function shooterLoginByKc() {
  return apiClient.get("/user/findUser", {
    headers: { Authorization: getAccessToken() },
  })
}

function* loginByKc() {
  return yield takeLatest(actionTypes.LOGIN_BY_KC, function* (action) {
    try {
      const data = yield call(shooterLoginByKc, action.payload)
      yield put({
        type: actionTypes.LOGIN_BY_KC_SUCCESS,
        payload: {
          ...data,
        },
      })
    } catch (error) {
      console.log(error)
    }
  })
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    localStorage.removeItem("accessToken")
    localStorage.removeItem("botAccessTokenEncoded")
    localStorage.removeItem("botUniqueUserId")
    history("/")
  } catch (error) {
    yield put(apiError(error))
  }
}

async function shooterResetPassword(userInfo) {
  return apiClient.post("user/reset-password", userInfo)
}

function* resetPassword() {
  return yield takeLatest(
    actionTypes.POST_RESET_PASSWORD_REQUEST,
    function* (action) {
      try {
        const data = yield call(shooterResetPassword, action.payload)
        yield put({
          type: actionTypes.POST_RESET_PASSWORD_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        })
        action.history("/login")
      } catch (error) {
        console.log(error)
        action.helpers.setStatus({ success: false })
        console.error(
          error?.response?.data?.message
            ? error?.response?.data.message
            : error.message,
        )
        action.helpers.setErrors({
          submit: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        })
        action.helpers.setSubmitting(false)
      }
    },
  )
}

function* authSaga() {
  yield takeEvery(actionTypes.GET_USER_INFO_REQUEST, getUserInfo)
  yield takeEvery(actionTypes.LOGIN_USER, loginUser)
  yield takeEvery(actionTypes.LOGOUT_USER, logoutUser)
  yield all([signInGoogle(), resetPassword(), loginByKc(), getEmbeddedToken()])
}

export default authSaga
