import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import logoDark from "../assets/images/ep_logo_trippy.png"
import logoLight from "../assets/images/ep_logo_trippy.png"
import PropTypes from "prop-types"

import withRouter from "components/Common/withRouter"

import backgroundImg from "./../assets/images/mgpt-bg-compressed.webp"

import { Button } from "@material-tailwind/react"
import UserService from "KeyCloackServices/UserService"

const Welcome = () => {
  document.title = "Login | MushPro by The Entheology Project "

  const style = {
    authScreenPage: {
      height: "100vh",
      backgroundImage: `url(${backgroundImg})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      display: "flow-root",
    },
  }

  useEffect(() => {
    localStorage.removeItem("botUniqueUserId")
    localStorage.removeItem("botAccessTokenEncoded")
  }, [])

  return (
    <React.Fragment>
      <div className="account-pages sm:pt-0 pt-5 " style={style.authScreenPage}>
        <Container>
          <Row className="justify-center ">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img
                        src={logoDark}
                        alt="Mush Pro"
                        height="70px"
                        width="70px"
                        className="auth-logo-dark"
                      />
                      <img
                        src={logoLight}
                        alt=""
                        height="30"
                        className="auth-logo-light"
                      />
                    </Link>
                  </h3>

                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Welcome Back !
                    </h4>
                    <p className="text-muted text-center">
                      Sign in to continue to Mush Pro.
                    </p>
                    <div className="col-12 mt-4 text-center">
                      <Button
                        size="sm"
                        className="btn rounded-[0.25rem] btn-primary w-md waves-effect waves-light bg-[#7a6fbe]"
                        type="submit"
                        onClick={() =>
                          UserService.doLogin({
                            redirectUri: window.location.origin + "/",
                          })
                        }
                      >
                        Log In
                      </Button>
                    </div>
                  </div>
                  <div className="text-center">
                    <p>
                      Don't have an account ?{" "}
                      <Link
                        to="#"
                        className="text-[#7a6fbe] font-bold"
                        onClick={() =>
                          UserService.doLogin({
                            action: "register",
                            redirectUri: window.location.origin + "/",
                          })
                        }
                      >
                        {" "}
                        Signup{" "}
                      </Link>
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Welcome)

Welcome.propTypes = {
  history: PropTypes.object,
}
