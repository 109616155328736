import React from "react"
import { useSelector } from "react-redux"
import RegisterBot from "./RegisterBot"
import PersonalityChat from "./PersonalityChat"

const ProChat = props => {
  const { user } = useSelector(state => state.Login)
  const { isCreated } = useSelector(state => state.bot)
  document.title = "Personality | MushPro by The Entheology Project"
  return (
    <>
      {user?.isBotCreated || isCreated ? <PersonalityChat /> : <RegisterBot />}
    </>
  )
}

export default ProChat
