import withRouter from "components/Common/withRouter"
import { setSessionExpired, useMainController } from "context"
import React, { useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useMutation } from "react-query"

import { Col, Row, Card, CardBody, CardTitle, Button } from "reactstrap"
import http from "services/http-common"
import Features from "./Features"

const SubscriptionPlan = props => {
  const [dispatch] = useMainController()
  const [subscriptions, setSubscriptions] = useState([])
  document.title = "Subscription Plan | MushPro by The Entheology Project"

  const getPrompt = useMutation(
    () => {
      return http.get(
        "plan/getAll/?cp=1&ps=900",
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        setSubscriptions(data?.records)
      },
      onError: error => {
        toast.error(error)
      },
    },
  )

  const getFaqs = useCallback(() => {
    getPrompt.mutate()
  }, [])

  useEffect(() => {
    getFaqs()
  }, [])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Features />
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 2xl:mt-5">
            {subscriptions?.map(itm => (
              <div className="border border-zinc-200 bg-white text-zinc-950 shadow-sm dark:border-zinc-800 dark:bg-zinc-950 dark:text-zinc-50 relative flex flex-col justify-between rounded-xl p-6">
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col space-y-1.5 p-0">
                    <h3 className="text-2xl leading-none tracking-tight font-medium">
                      {itm.plan_name}
                    </h3>
                  </div>
                  <div className="flex flex-col gap-3 p-0">
                    <div className="flex flex-col gap-1">
                      <span className="text-5xl font-semibold">
                        <p className="inline-block text-3xl font-normal">$</p>
                        {itm.price}
                      </span>
                      <span className="text-[#71717a]">
                        {" "}
                        {itm.price === 0 ? "Forever" : "Per Month"}
                      </span>
                    </div>
                    <hr className="shrink-0 dark:bg-zinc-500 h-[1px] w-full bg-zinc-300" />
                    <ul className="mt-0 flex flex-col gap-4 text-sm font-medium">
                      {itm?.custom_personality && (
                        <li className="flex flex-row items-start gap-3">
                          <p className="size-5 text-zinc-700">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-check size-5"
                            >
                              <path d="M20 6 9 17l-5-5"></path>
                            </svg>
                          </p>
                          <span>Customizable Personality </span>
                        </li>
                      )}
                      {itm?.psilo_assesment && (
                        <li className="flex flex-row items-start gap-3">
                          <p className="size-5 text-zinc-700">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-check size-5"
                            >
                              <path d="M20 6 9 17l-5-5"></path>
                            </svg>
                          </p>
                          <span>PsiloScreen Intake & Risk Assessment</span>
                        </li>
                      )}

                      {itm?.entheogpt_store && (
                        <li className="flex flex-row items-start gap-3">
                          <p className="size-5 text-zinc-700">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-check size-5"
                            >
                              <path d="M20 6 9 17l-5-5"></path>
                            </svg>
                          </p>
                          <span>EntheoGPT Store</span>
                        </li>
                      )}

                      {itm?.conversation_starters && (
                        <li className="flex flex-row items-start gap-3">
                          <p className="size-5 text-zinc-700">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-check size-5"
                            >
                              <path d="M20 6 9 17l-5-5"></path>
                            </svg>
                          </p>
                          <span>Conversation Starters </span>
                        </li>
                      )}
                      {itm.website_scraping !== 0 && (
                        <li className="flex flex-row items-start gap-3">
                          <p className="size-5 text-zinc-700">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-check size-5"
                            >
                              <path d="M20 6 9 17l-5-5"></path>
                            </svg>
                          </p>
                          <span>
                            Up to {itm.website_scraping} Website Scrape
                          </span>
                        </li>
                      )}
                      {itm.doc_training !== 0 && (
                        <li className="flex flex-row items-start gap-3">
                          <p className="size-5 text-zinc-700">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-check size-5"
                            >
                              <path d="M20 6 9 17l-5-5"></path>
                            </svg>
                          </p>
                          <span>Up to {itm.doc_training} Document Train</span>
                        </li>
                      )}
                      {itm.embedding !== 0 && (
                        <li className="flex flex-row items-start gap-3">
                          <p className="size-5 text-zinc-700">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-check size-5"
                            >
                              <path d="M20 6 9 17l-5-5"></path>
                            </svg>
                          </p>
                          <span>
                            Embedding (
                            {itm.embedding
                              ? itm.embedding === 1
                                ? `${itm.embedding} site`
                                : `Up to ${itm.embedding} sites`
                              : "No"}
                            )
                          </span>
                        </li>
                      )}

                      {itm.seeker_coach_match && (
                        <li className="flex flex-row items-start gap-3">
                          <p className="size-5 text-zinc-700">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-check size-5"
                            >
                              <path d="M20 6 9 17l-5-5"></path>
                            </svg>
                          </p>
                          <span>Confidential Seeker to Coach Matching</span>
                        </li>
                      )}
                      {itm.pushMessaging !== 0 && (
                        <li className="flex flex-row items-start gap-3">
                          <p className="size-5 text-zinc-700">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              className="lucide lucide-check size-5"
                            >
                              <path d="M20 6 9 17l-5-5"></path>
                            </svg>
                          </p>
                          <span>
                            Push Messaging (
                            {itm.pushMessaging
                              ? `Up to ${itm.pushMessaging} messages/month`
                              : "No"}
                            )
                          </span>
                        </li>
                      )}

                      <li className="flex flex-row items-start gap-3">
                        <p className="size-5 text-zinc-700">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            className="lucide lucide-check size-5"
                          >
                            <path d="M20 6 9 17l-5-5"></path>
                          </svg>
                        </p>
                        <span>{itm.support} Support </span>
                      </li>

                      {/* <p className="text-xs text-[#71717a]">
                        Chatbots get deleted after 14 days of inactivity on the
                        free plan.
                      </p> */}
                    </ul>
                  </div>
                </div>
                <div className="mt-6">
                  <Button className="w-full waves-effect waves-light btn btn-primary inline-flex items-center justify-center whitespace-nowrap rounded-xl text-sm font-medium transition-all duration-100 ease-in-out disabled:cursor-not-allowed bg-white-gradient text-zinc-950 hover:border-zinc-500 border active:border-zinc-400 disabled:opacity-60 shadow-button hover:shadow-button-hover hover:bg-white-gradient-hover active:bg-white-gradient-hover active:opacity-70 disabled:hover:shadow-button disabled:hover:bg-white-gradient disabled:hover:border-zinc-200 disabled:hover:text-zinc-950 h-auto px-4 py-3">
                    Get started
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </CardBody>
        <div  className="max-w-5xl mx-auto my-3">
          <div  className="text-3xl md:text-4xl font-semibold pb-8 text-center">
            <h3 className="text-2xl">Dedicated Support for Your Success </h3>
            <p>
              At Entheo.Pro, we prioritize your success. Our dedicated support
              team is here to assist you from initial setup to ongoing
              optimization. By integrating our advanced AI capabilities into
              your coaching practice, you can focus on what you do best –
              helping your clients achieve their goals.
            </p>
            <br />
            <p>
              Explore our plans today and discover how Entheo.Pro can transform
              your coaching practice into a more efficient, responsive, and
              client-centered service. For more information, visit Entheo.Pro.
            </p>
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}

export default withRouter(SubscriptionPlan)
