import React, { useEffect } from "react"
import ServicesHomeTwo from "./ServicesHomeTwo"
import HeroSection from "./HeroSection"
import Testimonial from "./Testimonial"
import useToggle from "./useToggle"
import Header from "./Header"
import CallOfAction from "./CallOfAction"
import Footer from "./Footer"
import Drawer from "./Mobile/Drawer"
import { useNavigate } from "react-router-dom"
import UserService from "KeyCloackServices/UserService"

function Home() {
  const [drawer, drawerAction] = useToggle(false)
  const navigate = useNavigate()

  useEffect(() => {
    localStorage.removeItem("botUniqueUserId")
    localStorage.removeItem("botAccessTokenEncoded")
    UserService.isLoggedIn() && navigate("/dashboard")
  }, [])
  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <Header action={drawerAction.toggle} />
      <HeroSection  />
      <ServicesHomeTwo  />
      <Testimonial />
      <CallOfAction className="appie-signup-8-area" />
      <Footer />
    </>
  )
}

export default Home
