import React from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

const PagesUnderMaintenance = () => {
  document.title = "Under Maintenance | MushPro by The Entheology Project "

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="6" xl="5">
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div className="ex-page-content text-center">
                    <h2 className="text-2xl font-bold my-3">
                      Under maintenance
                    </h2>
                    <h3 className="">
                      This page you're looking for is currently under
                      maintenance and will be back soon.
                    </h3>
                    <br />

                    <Link
                      className="btn btn-info mb-4 waves-effect waves-light"
                      to="/"
                    >
                      <i className="mdi mdi-home"></i> Back to Home
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PagesUnderMaintenance
