import React, { useEffect, useState } from "react"

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { FaCheck } from "react-icons/fa"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { httpForMushGpt } from "services/http-common"
import { useMutation } from "react-query"
import { useDispatch } from "react-redux"
import toast from "react-hot-toast"
import { setSessionExpired } from "context"

export default function ModelSelection() {
  const dispatch = useDispatch()
  const [createmenu, setCreateMenu] = useState(false)
  const { botProfile } = useSelector(state => state.bot)
  const [allModel, setAllModel] = useState(null)
  const [selectModel, setSelectModel] = useState(localStorage.getItem("model"))
  const location = useLocation()
  const [loading, setLoading] = useState(false)

  const handleChange = text => {
    localStorage.setItem("model", text)
    setSelectModel(text)
  }
  const getAllMModels = useMutation(
    () => {
      return httpForMushGpt.post(
        `/llms/getall`,
        {
          isEnabled: true,
        },
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        setAllModel(data?.records)
        setLoading(false)
      },
      onError: error => {
        toast.error(error.response.data.message)
        setLoading(false)
      },
      onMutate: () => {
        setLoading(true)
      },
      onSettled: () => {
        setLoading(false)
      },
    },
  )
  useEffect(() => {
    getAllMModels.mutate()
    if (!localStorage.getItem("model")) {
      localStorage.setItem("model", "GPT-4o")
      setSelectModel("GPT-4o")
    }
  }, [])
  return (
    <>
      <div className="d-sm-block flex justify-start items-center gap-1">
        {location.pathname === "/magic-myc" ? (
          <strong> MushGPT AI</strong>
        ) : location.pathname === "/my-bot" ? (
          <strong>{botProfile?.botName}</strong>
        ) : (
          ""
        )}

        <Dropdown
          isOpen={createmenu}
          toggle={() => setCreateMenu(!createmenu)}
          className="d-inline-block"
        >
          <div className="dropdown dropdown-topbar d-inline-block">
            <DropdownToggle className="btn btn-light" tag="button">
              {selectModel} <i className="mdi mdi-chevron-down"></i>
            </DropdownToggle>

            <DropdownMenu className="dropdown-menu-end">
              {allModel?.map(model => {
                return (
                  <>
                    {model.isEnabled && (
                      <DropdownItem
                        key={model.name}
                        tag="a"
                        href="#"
                        onClick={() => {
                          handleChange(model.name)
                        }}
                      >
                        <span className="flex justify-between items-center gap-2 cursor-pointer ">
                          {model.name}{" "}
                          {model.status === "private" && "(Private)"}
                          {localStorage.getItem("model") === model.name && (
                            <FaCheck />
                          )}
                        </span>
                      </DropdownItem>
                    )}
                  </>
                )
              })}
            </DropdownMenu>
          </div>
        </Dropdown>
      </div>
    </>
  )
}
