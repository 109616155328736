import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import logoDark from "../../assets/images/ep_logo_trippy.png"
import logoLight from "../../assets/images/ep_logo_trippy.png"
import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import withRouter from "components/Common/withRouter"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { resetPass } from "../../store/actions"
import backgroundImg from "./../../assets/images/mgpt-bg-compressed.webp"
import OtpInput from "./OTPInput/OTPInput"
import { useNavigate } from "react-router-dom"

const initialValues = {
  password: "",
  submit: null,
}

const validationSchema = Yup.object({
  password: Yup.string().max(255).required("New Password is required"),
})
const ResetPassword = props => {
  document.title = "Forget Password | MushPro by The Entheology Project"
  const [otp, setOtp] = useState("")
  const [otpError, setOtpError] = useState(null)

  const history = useNavigate()

  const onChange = value => setOtp(value)

  const dispatch = useDispatch()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    // enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      if (otp.length === 6) {
        setOtpError(null)
        dispatch(
          resetPass(
            { otp: otp, newPassword: values.password },
            history,
            helpers,
          ),
        )
      } else {
        setOtpError("Please Enter the OTP correctly.")
      }
    },
  })

  const selectForgotPasswordState = state => state.ForgetPassword
  const ForgotPasswordProperties = createSelector(
    selectForgotPasswordState,
    forgetPassword => ({
      forgetError: forgetPassword.forgetError,
      forgetSuccessMsg: forgetPassword.forgetSuccessMsg,
    }),
  )

  const { forgetError, forgetSuccessMsg } = useSelector(
    ForgotPasswordProperties,
  )

  const style = {
    authScreenPage: {
      height: "100vh",
      backgroundImage: `url(${backgroundImg})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      display: "flow-root",
    },
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5" style={style.authScreenPage}>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img
                        src={logoDark}
                        alt=""
                        height="70px"
                        width="70px"
                        className="auth-logo-dark"
                      />
                      <img
                        src={logoLight}
                        alt=""
                        height="70px"
                        width="70px"
                        className="auth-logo-light"
                      />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-3 text-center">
                      Reset Password
                    </h4>
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {otpError && otpError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {otpError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}
                    <form
                      className="form-horizontal mt-4"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                      }}
                    >
                      <div className="mb-3 !flex !justify-center">
                        <OtpInput
                          value={otp}
                          valueLength={6}
                          onChange={onChange}
                        />
                        {validation.touched.otp && validation.errors.otp ? (
                          <FormFeedback type="invalid">
                            {validation.errors.otp}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="password">New Password</Label>
                        <Input
                          name="password"
                          className="form-control"
                          placeholder="Enter new Password"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <Row className="mb-3">
                        <div className="col-12 text-end">
                          <button
                            disabled={validation.isSubmitting}
                            className="btn btn-primary w-md waves-effect waves-light bg-[#7a6fbe]"
                            type="submit"
                          >
                            Reset Password
                          </button>
                        </div>
                      </Row>
                    </form>
                    <div className="text-center">
                      <p>
                        Remember It ?{" "}
                        <Link
                          to="/login"
                          className="text-primary text-[#7a6fbe] font-bold"
                        >
                          {" "}
                          Sign In{" "}
                        </Link>{" "}
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ResetPassword.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ResetPassword)
