import { fork, put, all, call, takeLatest } from "redux-saga/effects"
import toast from "react-hot-toast"

// Login Redux States
import * as actionTypes from "./actionTypes"

import apiClient from "helpers/apiClient"
import { getAccessToken } from "helpers/jwt-token-access/accessToken"
import { LOGIN_BY_KC } from "../login/actionTypes"

// function* updateProfile({ payload: { user } }) {
//   try {
//     const response = yield call(postProfile, {
//       user: user,
//     })
//     yield put(profileSuccess(response))
//   } catch (error) {
//     yield put(profileError(error))
//   }
// }

async function shooterUpdateProfile(userInfo) {
  return apiClient.put(`/user/update/`, userInfo.user, {
    headers: { Authorization: getAccessToken() },
  })
}

function* updateProfile() {
  return yield takeLatest(actionTypes.UPDATE_PROFILE, function* (action) {
    try {
      const data = yield call(shooterUpdateProfile, action.payload)
      yield put({
        type: LOGIN_BY_KC,
      })

      yield put({
        type: actionTypes.UPDATE_PROFILE_SUCCESS,
        payload: {
          ...data,
        },
      })
      toast.success("Updated successfully.")
    } catch (error) {
      toast.error(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
      )
      // action.helpers.setStatus({ success: false })
      // action.helpers.setErrors({
      //   submit: error?.response?.data?.message
      //     ? error?.response?.data?.message
      //     : error?.message,
      // })
      // action.helpers.setSubmitting(false)
    }
  })
}
async function shooterDeleteProfile(userInfo) {
  return apiClient.delete(`/user/delete`, {
    headers: { Authorization: getAccessToken() },
    data: {
      id: userInfo.data,
    },
  })
}

function* deleteProfile() {
  return yield takeLatest(
    actionTypes.POST_Delete_Profile_REQUEST,
    function* (action) {
      try {
        yield call(shooterDeleteProfile, action.payload)
        yield put({
          type: actionTypes.POST_Delete_Profile_SUCCESS,
        })
        action.payload.navigate("/logout")
      } catch (error) {
        console.log(error)
        action.helpers.setStatus({ success: false })
        action.helpers.setErrors({
          submit: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        })
        action.helpers.setSubmitting(false)
      }
    },
  )
}

async function shooterGetAllTitles(data) {
  return apiClient.post(
    `/user/metadata/title/search/`,
    {
      title: data.data,
      ps: 10,
      cp: 1,
    },
    {
      headers: { Authorization: getAccessToken() },
    },
  )
}

function* getAllTitles() {
  return yield takeLatest(actionTypes.GET_ALL_TITLES, function* (action) {
    try {
      const data = yield call(shooterGetAllTitles, action.payload)
      yield put({
        type: actionTypes.GET_ALL_TITLES_SUCCESS,
        payload: {
          ...data,
        },
      })
    } catch (error) {
      console.log(error)
      action.helpers.setStatus({ success: false })
      action.helpers.setErrors({
        submit: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
      })
      action.helpers.setSubmitting(false)
    }
  })
}

async function shooterGetAllEducations(data) {
  return apiClient.post(
    `/user/metadata/education/search/`,
    {
      education: data.data,
      ps: 10,
      cp: 1,
    },
    {
      headers: { Authorization: getAccessToken() },
    },
  )
}

function* getAllEducations() {
  return yield takeLatest(actionTypes.GET_ALL_EDUCATIONS, function* (action) {
    try {
      const data = yield call(shooterGetAllEducations, action.payload)
      yield put({
        type: actionTypes.GET_ALL_EDUCATIONS_SUCCESS,
        payload: {
          ...data,
        },
      })
    } catch (error) {
      console.log(error)
      action.helpers.setStatus({ success: false })
      action.helpers.setErrors({
        submit: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
      })
      action.helpers.setSubmitting(false)
    }
  })
}

async function shooterGetAllExpertise(data) {
  return apiClient.post(
    `/user/metadata/expertise/search/`,
    {
      expertise: data.data,
      ps: 10,
      cp: 1,
    },
    {
      headers: { Authorization: getAccessToken() },
    },
  )
}

function* getAllExpertise() {
  return yield takeLatest(actionTypes.GET_ALL_EXPERTISES, function* (action) {
    try {
      const data = yield call(shooterGetAllExpertise, action.payload)
      yield put({
        type: actionTypes.GET_ALL_EXPERTISES_SUCCESS,
        payload: {
          ...data,
        },
      })
    } catch (error) {
      console.log(error)
      action.helpers.setStatus({ success: false })
      action.helpers.setErrors({
        submit: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
      })
      action.helpers.setSubmitting(false)
    }
  })
}

async function shooterGetAllTranings(data) {
  return apiClient.post(
    `/user/metadata/training/search/`,
    {
      training: data.data,
      ps: 10,
      cp: 1,
    },
    {
      headers: { Authorization: getAccessToken() },
    },
  )
}

function* getAllTranings() {
  return yield takeLatest(actionTypes.GET_ALL_TRAININGS, function* (action) {
    try {
      const data = yield call(shooterGetAllTranings, action.payload)
      yield put({
        type: actionTypes.GET_ALL_TRAININGS_SUCCESS,
        payload: {
          ...data,
        },
      })
    } catch (error) {
      console.log(error)
      action.helpers.setStatus({ success: false })
      action.helpers.setErrors({
        submit: error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
      })
      action.helpers.setSubmitting(false)
    }
  })
}

async function shooterhandleUploadFiles(file) {
  return apiClient.post("/user/fileUploader", file, {
    headers: {
      Authorization: getAccessToken(),
      "Content-type": "multipart/form-data",
    },
  })
}

function* handleUploadFiles() {
  return yield takeLatest(actionTypes.UPLOAD_FILE_REQUEST, function* (action) {
    try {
      const data = yield call(shooterhandleUploadFiles, action.payload)
      action.callBack({ status: "success", returnData: data })
    } catch (error) {
      action.callBack({
        status: "error",
        returnData: error?.response?.data?.message
          ? error?.response?.data
          : error,
      })
    }
  })
}

function* ProfileSaga() {
  yield all([
    fork(deleteProfile),
    fork(updateProfile),
    fork(getAllTitles),
    fork(getAllEducations),
    fork(getAllExpertise),
    fork(getAllTranings),
    fork(handleUploadFiles),
  ])
}

export default ProfileSaga
