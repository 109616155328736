import React, { useState } from "react"
import { useGoogleLogin } from "@react-oauth/google"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { Button } from "reactstrap"
import { useDispatch } from "react-redux"
import CircularProgress from "@mui/material/CircularProgress"
import { signInGoogle } from "store/actions"
import { FcGoogle } from "react-icons/fc"

const GoogleSignIn = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const signInWithGoogle = async values => {
    dispatch(signInGoogle({ userinfo: values }, navigate))
  }

  const login = useGoogleLogin({
    onSuccess: async CredentialResponse => {
      setLoading(true)
      if (CredentialResponse) {
        await axios
          .get(
            "https://www.googleapis.com/oauth2/v2/userinfo?access_token=" +
              CredentialResponse.access_token,
          )
          .then(async res => {
            if (res) {
              setLoading(false)
              signInWithGoogle(res.data)
            }
          })
          .catch(error => {
            setLoading(false)
            console.log(error.response.data.message)
          })
      }
    },
  })

  return (
    <>
      <div className="google-services shadow-sm shadow-[#7a6fbe] w-2/3 mx-auto mb-2 ">
        <Button
          className="btn w-full cursor-pointer waves-effect waves-light bg-white text-[#7a6fbe] border-0 hover:bg-white hover:text-[#7a6fbe]  font-bold flex justify-center items-center gap-3 rounded-none"
          onClick={() => login()}
        >
          {!loading ? <FcGoogle className="text-2xl" /> : ""}
          {!loading ? "Sign in with Google" : <CircularProgress />}
        </Button>
      </div>
    </>
  )
}

export default GoogleSignIn
