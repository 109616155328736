import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Select,
  Option,
  Chip,
  Button,
  Input,
  Tooltip,
} from "@material-tailwind/react"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-hot-toast"
import http, { httpForMushGpt } from "services/http-common"
import {
  CloudArrowUpIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline"
import { useMainController, setSessionExpired } from "context"
import { formatDate } from "lib/util"
import * as messages from "services/messages"
import EditMetaContent from "./EditMetaContent"
import Table from "pages/widgets/table"

export function TrainBookPro() {
  const [dispatch] = useMainController()

  const [contentId, setContentId] = useState(null)
  const [editorOpen, setEditorOpen] = useState(false)
  const queryClient = useQueryClient()

  const cp = queryClient.getQueryData("books-cp")
  const ps = queryClient.getQueryData("books-ps")
  const filters = queryClient.getQueryData("books-filters")
  const sorters = queryClient.getQueryData("books-sorters")

  const { register, handleSubmit, reset } = useForm({
    mode: "onSubmit",
  })
  const [books, setBooks] = useState([])
  const [selectedFilter, setSelectedFilter] = useState("title")
  const [checkValues, setCheckValues] = useState([])
  const [currentPage, setCurrentPage] = useState(cp || 1)
  const [pageSize, setPageSize] = useState(ps || 50)
  const [totalPage, setTotalPage] = useState(10000)
  const [filterValues, setFilterValues] = useState(filters || {})
  const [sorterValues, setSorterValues] = useState(
    sorters || { updated_at: { desc: true } },
  )

  useQuery("books-cp", () => currentPage)
  useQuery("books-ps", () => pageSize)
  useQuery("books-filters", () => filterValues)
  useQuery("books-sorters", () => sorterValues)

  const tableHeaders = [
    {
      key: "name",
      name: "name",
      // filter: {
      //   type: "search",
      // },
      sorter: true,
    },
    {
      key: "userName",
      name: " user",
      width: "30%",
      // filter: {
      //   type: "search",
      // },
      sorter: true,
    },
    {
      key: "status",
      name: "status",
      width: "20%",
      filter: {
        type: "check",
        kinds: [
          { name: "ready", value: "ready" },
          { name: "pending", value: "pending" },
          { name: "failed", value: "failed" },
          { name: "done", value: "done" },
        ],
      },
      sorter: true,
    },
    {
      key: "private",
      name: "private",
      width: "20%",
      // filter: {
      //   type: "radio",
      //   kinds: [
      //     { name: "private", value: 1 },
      //     { name: "public", value: 0 },
      //   ],
      // },
      sorter: true,
    },
    { key: "updated_at", name: "update at", sorter: true },
  ]

  const errorFunc = error => {
    let message
    switch (error.response.status) {
      case 500: {
        message = error.response.data.message || messages.SERVER_ERROR
        break
      }
      case 404: {
        message = messages.NOT_FOUND_ERROR
        break
      }
      case 400: {
        message = messages.BAD_REQUEST
        break
      }
      default: {
        message = error.message
      }
    }
    toast.error(message)
  }

  const { isError, error, data } = useQuery(
    ["books", currentPage, pageSize, filterValues, sorterValues],
    () => {
      if (
        filterValues[selectedFilter] &&
        filterValues[selectedFilter].length > 0
      ) {
        return httpForMushGpt.post(
          `train/docs/search`,
          {
            cp: currentPage,
            ps: pageSize,
            filters: {
              [selectedFilter]: filterValues[selectedFilter],
            },
          },
          setSessionExpired,
          dispatch,
        )
      } else {
        return httpForMushGpt.get(
          `train/docs/?cp=${currentPage}&ps=${pageSize}`,
          null,
          setSessionExpired,
          dispatch,
        )
      }
    },
    {
      keepPreviousData: true,
      refetchInterval: 5000,
      queryKey: ["books", currentPage, pageSize], // Unique key for the query
      staleTime: 30000, // Keep data in cache for 30 seconds
      retryOnMount: true,
    },
  )

  useEffect(() => {
    if (isError) {
      console.log(error)
      toast.error("Fetch data failed")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  useEffect(() => {
    if (data && data.data) {
      const { docs, pageSize, total } = data.data
      const newDocs = docs.map(doc => {
        return {
          ...doc,
          key: doc.id,
          name: (
            <Tooltip
              content={doc.name}
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
            >
              <a href={doc.originalUrl} target="_blank" rel="noreferrer">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-semibold"
                >
                  {doc.name.length > 40
                    ? doc.name.slice(0, 40) + "..."
                    : doc.name}
                </Typography>
              </a>
            </Tooltip>
          ),
          userName: (
            <Typography
              variant="small"
              color="blue-gray"
              className="font-semibold"
            >
              {doc?.uploadedBy ? doc?.uploadedBy : doc?.user?.firstName}
            </Typography>
          ),
          updated_at: (
            <Typography
              variant="small"
              color="blue-gray"
              className="font-semibold"
            >
              {formatDate(doc.updated_at)}
            </Typography>
          ),
          status: (
            <Chip
              variant="gradient"
              color={status(doc.status)}
              value={doc.status}
              className="py-0.5 px-2 text-[11px] font-medium"
            />
          ),
          private: (
            <Chip
              variant="gradient"
              color={doc.private ? "red" : "blue"}
              value={doc.private ? "private" : "public"}
              className="py-0.5 px-2 text-[11px] font-medium"
            />
          ),
        }
      })
      setBooks(newDocs)

      setTotalPage(Math.ceil(total / pageSize))
    }
  }, [data])

  const fileUpload = useMutation(
    async file => {
      return httpForMushGpt.filepost(
        `train/book`,
        file,
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: ({ data }) => {
        toast.success(data.message)
        reset()
      },
      onError: error => {
        errorFunc(error)
      },
    },
  )

  const onSubmit = data => {
    if (data.books.length !== 0) {
      const formData = new FormData()
      for (let i = 0; i < data.books.length; i++) {
        formData.append("books", data.books[i])
      }
      fileUpload.mutate(formData)
    } else {
      toast.error("Please select any file")
    }
  }

  const status = value => {
    switch (value) {
      case "ready": {
        return "blue"
      }
      case "pending": {
        return "purple"
      }
      case "done": {
        return "green"
      }
      case "failed": {
        return "red"
      }
      default: {
      }
    }
  }

  const onChangeCheckValues = values => {
    setCheckValues(values)
  }

  const onChangePagination = (cp, ps) => {
    setCurrentPage(cp)
    setPageSize(ps)

    queryClient.setQueryData("books-cp", cp)
    queryClient.setQueryData("books-ps", ps)
  }

  const onChangeFilter = filterValues => {
    setFilterValues(filterValues)
    queryClient.setQueryData("books-filters", { ...filterValues })
  }

  const onChangeSorter = sorterValues => {
    setSorterValues({ ...sorterValues })
    queryClient.setQueryData("books-sorters", { ...sorterValues })
  }

  const onSearchFileName = data => {
    let selectedFilterVar = selectedFilter

    setFilterValues(vals => {
      return {
        ...vals,
        [selectedFilterVar]: data.name,
      }
    })
  }

  const toogleOpenEditor = () => {
    if (editorOpen === true) {
      setContentId(null)
    }
    setEditorOpen(!editorOpen)
  }

  // const handleEdit = id => {
  //   setContentId(id)
  //   toogleOpenEditor()
  // }

  const onChageSelectedFilter = val => {
    setSelectedFilter(val)
  }

  window.document.title = "MushGpt | Admin | Documents"

  return (
    <>
      <div className="mt-4 mb-8 flex flex-col gap-12">
        <Card>
          <CardHeader color="gray" className="mb-8 p-6 bg-[#887bd9]">
            <Typography variant="h6" color="white">
              All Documents
            </Typography>
          </CardHeader>
          <CardBody className="overflow-x-auto px-2 pt-0 pb-2">
            {/* <form onSubmit={handleSubmit(onSubmit)} className="p-4">
              <Typography color="gray" variant="h6">
                Upload book
              </Typography>
              <div className="flex h-full w-full flex-row items-center gap-6">
                <input
                  className="border-neutral-300 text-neutral-700 file:bg-neutral-100 file:text-neutral-700 hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary relative m-0 block w-full min-w-0 flex-auto rounded border border-solid bg-clip-padding px-3 py-[0.32rem] text-base font-normal transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:px-3 file:py-[0.32rem] file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] focus:outline-none"
                  type="file"
                  id="formFile"
                  name="books"
                  {...register("books")}
                  multiple
                />
                <Button
                  className="flex items-center gap-3 py-2 px-3 bg-[#7a6fbe]"
                  type="submit"
                  disabled={fileUpload.isLoading}
                >
                  <CloudArrowUpIcon strokeWidth={2} className="h-5 w-5" />{" "}
                  Upload
                </Button>
              </div>
            </form> */}
            <div className="flex w-full justify-end py-1 pr-2">
              <form onSubmit={handleSubmit(onSearchFileName)}>
                <div className="flex">
                  <div className="flex items-center gap-2">
                    <div className="min-w-[100px] max-w-[110px] first:[&>div]:sm:!min-w-[100px] first:[&>div]:min-w-auto">
                      <Select
                        label="Search by"
                        className="min-w-[100px] max-w-[110px]"
                        value={selectedFilter}
                        onChange={onChageSelectedFilter}
                      >
                        <Option value="originalUrl">URL</Option>
                        <Option value="name">Name</Option>
                        <Option value="title">Title</Option>
                        <Option value="author">Author</Option>
                        <Option value="abstract">Abstract</Option>
                      </Select>
                    </div>
                    <Input
                      icon={<MagnifyingGlassIcon className="h-4 w-4" />}
                      placeholder="Search"
                      className=" !border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                      labelProps={{
                        className: "hidden",
                      }}
                      containerProps={{ className: "min-w-[100px]" }}
                      {...register("name")}
                    />
                  </div>
                </div>
              </form>
            </div>
            <Table
              tableHeaders={tableHeaders}
              data={books}
              paginationPosition="both"
              pagination={{
                pageSizeKind: [
                  { name: "50 items per page", value: 50 },
                  { name: "100 items per page", value: 100 },
                  { name: "150 items per page", value: 150 },
                  { name: "200 items per page", value: 200 },
                ],
                totalPage: totalPage,
                currentPage: currentPage,
                pageSize: pageSize,
              }}
              actions={null}
              onChangeCheckValues={onChangeCheckValues}
              checkData={checkValues}
              onChangePagination={onChangePagination}
              onChangeFilter={onChangeFilter}
              filters={filterValues}
              onChangeSorter={onChangeSorter}
              sorters={sorterValues}
            />
          </CardBody>
        </Card>
      </div>

      <EditMetaContent
        toogleOpenEditor={toogleOpenEditor}
        id={contentId}
        open={editorOpen}
      />
    </>
  )
}

export default TrainBookPro
