import * as actionTypes from "./actionTypes"

export const updateProfile = (user, setLoading) => {
  return {
    type: actionTypes.UPDATE_PROFILE,
    payload: { user, setLoading },
  }
}

export const editProfile = user => {
  return {
    type: actionTypes.EDIT_PROFILE,
    payload: { user },
  }
}

export const profileError = error => {
  return {
    type: actionTypes.PROFILE_ERROR,
    payload: error,
  }
}

export const getAllTitles = data => {
  return {
    type: actionTypes.GET_ALL_TITLES,
    payload: { data },
  }
}
export const getAllEducations = data => {
  return {
    type: actionTypes.GET_ALL_EDUCATIONS,
    payload: { data },
  }
}
export const getAllExpertise = data => {
  return {
    type: actionTypes.GET_ALL_EXPERTISES,
    payload: { data },
  }
}
export const getAllTrainings = data => {
  return {
    type: actionTypes.GET_ALL_TRAININGS,
    payload: { data },
  }
}
export function handleUploadFilesData(file, callBack) {
  return {
    type: actionTypes.UPLOAD_FILE_REQUEST,
    payload: file,
    callBack,
  }
}

export const resetProfileFlag = error => {
  return {
    type: actionTypes.RESET_PROFILE_FLAG,
  }
}

export function deleteProfile(data, navigate) {
  return {
    type: actionTypes.POST_Delete_Profile_REQUEST,
    payload: { data, navigate },
  }
}
