import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import ContentContainer from "./ContentContainer"
import ContentEdit from "./ContentEdit"
// import MagicMycQResponse from "../../../components/faqs/MagicMycQResponse"

import { Card } from "reactstrap"
import SimpleBar from "simplebar-react/dist"
import Spinners from "components/Common/Spinner"
import MagicMycQResponse from "./MagicMycQResponse"
import EditChatFooter from "pages/Chat Settings/EditChatFooter"

document.title = "Magic Myc | MushPro by The Entheology Project"

const MagicMycChat = props => {
  const [showSuggestions, setShowSuggestions] = useState(true)
  const { loading } = useSelector(state => state.msg)
  const [isLoading, setLoading] = useState(loading)

  const scroollRef = useRef(null)
  useEffect(() => {
    if (scroollRef.current) {
      scroollRef.current.getScrollElement().scrollTop =
        scroollRef.current.getScrollElement().scrollHeight
    }
  }, [])

  return (
    <>
      <div className="d-lg-flex">
        <div className="w-100 user-chat mt-4 mt-sm-0 ms-lg-3">
          <Card>
            <SimpleBar
              ref={scroollRef}
              className=" p-4  !min-h-[calc(100vh-240px)] md:max-h-[calc(100vh-270px)] lg:max-h-[calc(100vh-270px)] xl:max-h-[calc(100vh-270px)]"
            >
              {isLoading ? (
                <Spinners setLoading={setLoading} />
              ) : (
                <>
                  <ContentContainer
                    showSuggestions={showSuggestions}
                    setShowSuggestions={setShowSuggestions}
                  />
                </>
              )}
            </SimpleBar>
            <div className="p-3 ">
              <MagicMycQResponse />
              <hr className="my-2" />
              <ContentEdit />
            </div>
          </Card>
          <EditChatFooter />
        </div>
      </div>
    </>
  )
}

export default MagicMycChat
