import React from "react"
import projectBj from "../../assets/images/project-bg-2.jpg"
import UserService from "KeyCloackServices/UserService"

const style = {
  backgroundImg: {
    backgroundImage: `url(${projectBj})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flow-root",
  },
}

function CallOfAction({ className }) {
  return (
    <>
      <section className={`appie-project-3-area ${className} `}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                style={style.backgroundImg}
                className="
                appie-project-3-box
                d-block d-md-flex
                flex-col
                justify-content-center
                align-items-center
                wow
                text-center
                animated
                fadeInUp
              "
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className=" sm:text-2xl text-base font-bold text-white ">
                  <h3 className=" font-bold underline">
                    Signup for Mush.Pro AI Today!
                  </h3>
                  <p className="opacity-85 text-white sm:text-2xl text-base font-bold">
                    Join the waitlist and be the first to transform your
                    coaching practice with Mush.Pro.
                  </p>
                </div>
                <span
                  className="main-btn text-sm-base mt-3"
                  onClick={() =>
                    UserService.doLogin({
                      redirectUri: window.location.origin + "/",
                    })
                  }
                >
                  Get Started
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CallOfAction
