import {
  Input,
  Typography,
  Select,
  Option,
  Card,
  CardHeader,
  CardBody,
  Tooltip,
  Chip,
} from "@material-tailwind/react"
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from "react-hot-toast"
// import * as messages from "../../../services/messages";
import { useState, useEffect } from "react"
import EditContent from "./EditContent"
import { useForm } from "react-hook-form"
import EditWebMetaData from "./EditWebMetaData"
import { httpForMushGpt } from "services/http-common"
import { setSessionExpired, useMainController } from "context"
import { formatDate } from "lib/util"
import Table from "pages/widgets/table"

const TrainWebContentPro = () => {
  const [dispatch] = useMainController()
  const { handleSubmit, register } = useForm({
    mode: "onSubmit",
  })

  const queryClient = useQueryClient()

  const cp = queryClient.getQueryData("websites-cp")
  const ps = queryClient.getQueryData("websites-ps")
  const filters = queryClient.getQueryData("websites-filters")
  const sorters = queryClient.getQueryData("websites-sorters")

  const [saving, setSaving] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState("title")
  const [webSites, setWebSites] = useState([])
  const [websiteUrl, setWebsiteUrl] = useState("")
  const [editorOpen, setEditorOpen] = useState(false)
  const [editMetaDataOpen, setditMetaDataOpen] = useState(false)
  const [contentId, setContentId] = useState(null)
  const [checkValues, setCheckValues] = useState([])
  const [currentPage, setCurrentPage] = useState(cp || 1)
  const [pageSize, setPageSize] = useState(ps || 50)
  const [totalPage, setTotalPage] = useState(10000)
  const [filterValues, setFilterValues] = useState(filters || {})
  const [sorterValues, setSorterValues] = useState(
    sorters || { updated_at: { desc: true } },
  )

  useQuery("websites-cp", () => currentPage)
  useQuery("websites-ps", () => pageSize)
  useQuery("websites-filters", () => filterValues)
  useQuery("websites-sorters", () => sorterValues)

  const tableHeaders = [
    {
      key: "url",
      name: "Website URL",
      // filter: {
      //   type: "search",
      // },
      sorter: true,
    },
    { key: "userName", name: "registered by user", sorter: true },
    {
      key: "status",
      name: "status",
      filter: {
        type: "check",
        kinds: [
          { name: "ready", value: "ready" },
          { name: "scraping", value: "scraping" },
          { name: "scraped", value: "scraped" },
          { name: "scraping failed", value: "scraping failed" },
          { name: "training", value: "training" },
          { name: "trained", value: "trained" },
          { name: "training failed", value: "training failed" },
        ],
      },
      sorter: true,
    },
    { key: "updated_at", name: "update at", sorter: true },
  ]

  const { isError, error, data } = useQuery(
    ["websites", currentPage, pageSize, filterValues, sorterValues],
    () => {
      if (
        filterValues[selectedFilter] &&
        filterValues[selectedFilter].length > 0
      ) {
        return httpForMushGpt.post(
          `train/websites/search`,
          // ?cp=${currentPage}&ps=${pageSize}${
          //   JSON.stringify(filterValues) !== "{}"
          //     ? `&filters=${JSON.stringify(filterValues)}`
          //     : ""
          // }${
          //   JSON.stringify(sorterValues) !== "{}"
          //     ? `&sorters=${JSON.stringify(sorterValues)}`
          //     : ""
          // }
          {
            cp: currentPage,
            ps: pageSize,
            filters: {
              [selectedFilter]: filterValues[selectedFilter],
            },
          },
          setSessionExpired,
          dispatch,
        )
      } else {
        return httpForMushGpt.get(
          `/train/websites/?cp=${currentPage}&ps=${pageSize}`,
          null,
          setSessionExpired,
          dispatch,
        )
      }
    },
    {
      keepPreviousData: true,
      refetchInterval: 5000,
      queryKey: ["websites", currentPage, pageSize], // Unique key for the query
      staleTime: 30000, // Keep data in cache for 30 seconds
      retryOnMount: true,
    },
  )

  useEffect(() => {
    if (isError) {
      console.log(error)
      toast.error("Fetch data failed")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  useEffect(() => {
    if (data && data.data) {
      const { websites, pageSize, total } = data.data
      const websitesData = websites.map(website => {
        return {
          ...website,
          key: website.id,
          url: (
            <Tooltip
              content={website.url}
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
            >
              <Typography
                variant="small"
                color="blue-gray"
                className="font-semibold"
              >
                <a href={website.url} target="_blank" rel="noreferrer">
                  {website.url.length > 40
                    ? website.url.slice(0, 40) + "..."
                    : website.url}
                </a>
              </Typography>
            </Tooltip>
          ),
          userName: (
            <Typography
              variant="small"
              color="blue-gray"
              className="font-semibold"
            >
              {website?.uploadedBy
                ? website?.uploadedBy
                : website.user?.firstName}
            </Typography>
          ),
          updated_at: (
            <Typography
              variant="small"
              color="blue-gray"
              className="font-semibold"
            >
              {formatDate(website.updated_at)}
            </Typography>
          ),
          status: (
            <Chip
              variant="gradient"
              color={status(website.status)}
              value={website.status}
              className="py-0.5 px-2 text-[11px] font-medium"
            />
          ),
        }
      })
      setWebSites([...websitesData])
      setTotalPage(Math.ceil(total / pageSize))
    }
  }, [data])

  const onChangeWebsiteUrl = e => {
    setWebsiteUrl(e.target.value)
  }

  const onSearchURL = data => {
    let selectedFilterVar = selectedFilter
    setFilterValues(vals => {
      return {
        ...vals,
        [selectedFilterVar]: data.url,
      }
    })
  }

  const errorFunc = error => {
    let message = error.response.data.message
    toast.error(message)
  }

  const registerWebsite = useMutation(
    url => {
      return httpForMushGpt.post(
        "train/website",
        { url: url },
        null,
        setSessionExpired,
        dispatch,
      )
    },
    {
      onSuccess: () => {
        toast.success("Successfully registered!")
        setWebsiteUrl("")
      },
      onError: error => {
        errorFunc(error)
      },
      onSettled: () => {
        setSaving(false)
      },
      onMutate: () => {
        setSaving(true)
      },
    },
  )

  const toogleOpenEditor = () => {
    if (editorOpen === true) {
      setContentId(null)
    }
    setEditorOpen(!editorOpen)
  }

  const toogleOpenEditorMetaData = () => {
    if (editMetaDataOpen === true) {
      setContentId(null)
    }
    setditMetaDataOpen(!editMetaDataOpen)
  }

  const status = value => {
    switch (value) {
      case "ready": {
        return "blue"
      }
      case "scraping": {
        return "purple"
      }
      case "scraping failed": {
        return "red"
      }
      case "scraped": {
        return "cyan"
      }
      case "traning": {
        return "teal"
      }
      case "training failed": {
        return "pink"
      }
      case "trained": {
        return "green"
      }
      default: {
      }
    }
  }

  const onChangeCheckValues = values => {
    setCheckValues(values)
  }

  const onChangePagination = (cp, ps) => {
    setCurrentPage(cp)
    setPageSize(ps)

    queryClient.setQueryData("websites-cp", cp)
    queryClient.setQueryData("websites-ps", ps)
  }

  const onChangeFilter = filterValues => {
    setFilterValues(filterValues)
    queryClient.setQueryData("books-filters", { ...filterValues })
  }

  const onChangeSorter = sorterValues => {
    setSorterValues({ ...sorterValues })
    queryClient.setQueryData("books-sorters", { ...sorterValues })
  }

  const onChageSelectedFilter = val => {
    setSelectedFilter(val)
  }

  return (
    <div className="mt-12 mb-8 flex flex-col gap-12">
      <Card className="bg-[#FFFFFF]">
        <CardHeader variant="gradient" className="mb-8 p-6 bg-[#887bd9]">
          <Typography variant="h6" color="white">
            All Websites
          </Typography>
        </CardHeader>
        <CardBody className="overflow-x-auto px-2 pt-0 pb-2">
          <div className="flex w-full justify-end py-1 pr-2">
            <form onSubmit={handleSubmit(onSearchURL)}>
              <div className="flex ">
                <div className="flex items-center gap-2">
                  <div className="min-w-[100px] max-w-[110px] first:[&>div]:sm:!min-w-[100px] first:[&>div]:min-w-auto ">
                    <Select
                      label="Search by"
                      className="min-w-[100px] max-w-[110px]"
                      value={selectedFilter}
                      onChange={onChageSelectedFilter}
                    >
                      <Option value="url">URL</Option>
                      <Option value="title">Title</Option>
                      <Option value="author">Author</Option>
                      <Option value="abstract">Abstract</Option>
                    </Select>
                  </div>
                  <Input
                    icon={<MagnifyingGlassIcon className="h-4 w-4" />}
                    className=" !border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/10 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    containerProps={{
                      className: "min-w-0",
                    }}
                    placeholder="Search"
                    {...register("url")}
                  />
                </div>
              </div>
            </form>
          </div>
          <Table
            tableHeaders={tableHeaders}
            data={webSites}
            paginationPosition="both"
            pagination={{
              pageSizeKind: [
                { name: "50 items per page", value: 50 },
                { name: "100 items per page", value: 100 },
                { name: "150 items per page", value: 150 },
                { name: "200 items per page", value: 200 },
              ],
              totalPage: totalPage,
              currentPage: currentPage,
              pageSize: pageSize,
            }}
            actions={null}
            onChangeCheckValues={onChangeCheckValues}
            checkData={checkValues}
            onChangePagination={onChangePagination}
            onChangeFilter={onChangeFilter}
            filters={filterValues}
            onChangeSorter={onChangeSorter}
            sorters={sorterValues}
          />
        </CardBody>
      </Card>
      <EditContent
        toogleOpenEditor={toogleOpenEditor}
        id={contentId}
        open={editorOpen}
      />
      <EditWebMetaData
        toogleOpenEditor={toogleOpenEditorMetaData}
        id={contentId}
        open={editMetaDataOpen}
      />
    </div>
  )
}

export default TrainWebContentPro
